import { maintenanceSlice } from ".";
import { AppDispatch, store } from "../..";
import { IFile } from "../../../components/Files";
import { IFormExecutors } from "../../../componentsform/MaintenanceForm";
import { IWorkRequestFormValues } from "../../../componentsform/RequestPanelForm";
import { ITemplateForm } from "../../../componentsform/TemplateForm";
import { IApiResponse, ICursor, IDataToApi } from "../../../models";
import {
  IMaintenance,
  IExpense,
  IMaintenanceCategory,
  IMaintenanceDetails,
  IMaintenanceList,
  IMaintenanceOnHoldType,
  IMaintenanceType,
  IUserReport,
  IPotentialExecutors,
  IUpdateMaintenance,
  IAddWorkingTime,
  IFlow,
  IWorkCost,
  ITemplate,
  ITemplateDetails,
  IWorkCostDetail,
  IWorkRequest,
} from "../../../models/maintenances";
import { ITask } from "../../../models/settings";
import { LOCAL_STORAGE_MAINTENNACE_FILTERS_DASHBOARD } from "../../../pages/dashboard";
import api, { failedQueue, isRefreshing } from "../../../services";
import { dashboardSlice } from "../dashboard";

export const getMaintenancesCategoriesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IMaintenanceCategory[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(maintenanceSlice.actions.getMaintenancesCategories());
    let response = await api.get<IApiResponse<IMaintenanceCategory[]>>(`maintenance/categories/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getMaintenancesCategoriesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getMaintenancesCategoriesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(maintenanceSlice.actions.getMaintenancesCategoriesFail(error.message));
  }
};

export const getMaintenancesTypesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IMaintenanceType[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(maintenanceSlice.actions.getMaintenancesTypes());
    let response = await api.get<IApiResponse<IMaintenanceType[]>>(`maintenance/types/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getMaintenancesTypesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getMaintenancesTypesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(maintenanceSlice.actions.getMaintenancesTypesFail(error.message));
  }
};

export const getMaintenanceListXHR = async (
  data: Partial<{ mergeData: boolean } & IDataToApi<null, IApiResponse<IMaintenanceList>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(maintenanceSlice.actions.getMaintenanceList());
    let response = await api.get<IApiResponse<IMaintenanceList>>(
      "maintenance/orders/by_status/v2",
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      maintenanceSlice.actions.getMaintenanceListSuccess({
        ...response.data,
        mergeData: !!data.mergeData,
      }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getMaintenanceListXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getMaintenanceListFail(error.message));
  }
};

export const createFullOrderXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IMaintenance>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.createFullOrder());
    let response = await api.post<IApiResponse<IMaintenance>>(
      "maintenance/create_full_order/",
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.createFullOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createFullOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.createFullOrderFail(error.message));
  }
};

export const getPotentialExecutorsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPotentialExecutors>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  console.log("Data", data);
  try {
    dispatch(maintenanceSlice.actions.getPotentialExecutors());
    let response = await api.get<IApiResponse<IPotentialExecutors>>(
      `maintenance/potential_executors/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getPotentialExecutorsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPotentialExecutorsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getPotentialExecutorsFail(error.message));
  }
};

export const getMaintenanceXHR = async (
  data: Partial<IDataToApi<null & { loading }, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(maintenanceSlice.actions.getMaintenance(data.loading || ""));
    let response = await api.get<IApiResponse<IMaintenanceDetails>>(
      `maintenance/orders/details/${data.id}/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getMaintenanceSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getMaintenanceXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getMaintenanceFail(error.message));
  }
};

export const takeMaintenanceXHR = async (
  data: Partial<IDataToApi<{ order_info: number }, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.takeMaintenance());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/take_order/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.takeMaintenanceSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => takeMaintenanceXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.takeMaintenanceFail(error.message));
  }
};

export const startMaintenanceXHR = async (
  data: Partial<IDataToApi<{ order_info: number }, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.startMaintenance());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/start_work/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.startMaintenanceSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => startMaintenanceXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.startMaintenanceFail(error.message));
  }
};

export const onHoldMaintenanceXHR = async (
  data: Partial<
    IDataToApi<
      { order_info: number; reason: number; reason_text: string },
      IApiResponse<IMaintenanceDetails>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.onHoldMaintenance());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/set_on_held_order/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.onHoldMaintenanceSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => onHoldMaintenanceXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.onHoldMaintenanceFail(error.message));
  }
};

export const getStopWorkingReasonsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IMaintenanceOnHoldType[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getStopWorkingReasons());
    let response = await api.get<IApiResponse<IMaintenanceOnHoldType[]>>(
      `maintenance/stop_working_reason/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getStopWorkingReasonsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getStopWorkingReasonsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getStopWorkingReasonsFail(error.message));
  }
};

export const finishMaintenanceXHR = async (
  data: Partial<IDataToApi<{ order_info: number }, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.finishMaintenance());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/complete_order/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.finishMaintenanceSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => finishMaintenanceXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.finishMaintenanceFail(error.message));
  }
};

export const removeExecutorFromOrderXHR = async (
  data: Partial<IDataToApi<{ executor: number }, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.removeExecutorFromOrder());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/remove_executor_from_order/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.removeExecutorFromOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => removeExecutorFromOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.removeExecutorFromOrderFail(error.message));
  }
};

export const addExecutorsFromOrderXHR = async (
  data: Partial<
    IDataToApi<{ order_info: number; executors: IFormExecutors }, IApiResponse<IMaintenanceDetails>>
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(maintenanceSlice.actions.addExecutorsFromOrder());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/add_executors_to_order/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.addExecutorsFromOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addExecutorsFromOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.addExecutorsFromOrderFail(error.message));
  }
};

export const moveMaintenanceToOpenXHR = async (
  data: Partial<IDataToApi<{ order_info: number }, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.moveMaintenanceToOpen());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/move_order_to_open/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.moveMaintenanceToOpenSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => moveMaintenanceToOpenXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.moveMaintenanceToOpenFail(error.message));
  }
};

export const cancelMaintenanceXHR = async (
  data: Partial<
    IDataToApi<{ order_info: number; text: string }, IApiResponse<IMaintenanceDetails>>
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(maintenanceSlice.actions.cancelMaintenance());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/cancel_order/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.cancelMaintenanceSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => cancelMaintenanceXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.cancelMaintenanceFail(error.message));
  }
};

export const getCalendarMaintenancesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IMaintenance[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getCalendarMaintenances());
    let response = await api.get<IApiResponse<IMaintenance[]>>("maintenance/orders/calendar/", {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getCalendarMaintenancesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getCalendarMaintenancesXHR(data, dispatch));
      }
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getCalendarMaintenancesFail(error.message));
  }
};

export const getDroppableCalendarMaintenancesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IMaintenance[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getDroppableCalendarMaintenances());
    let response = await api.get<IApiResponse<IMaintenance[]>>("maintenance/orders/unplanned/", {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getDroppableCalendarMaintenancesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getDroppableCalendarMaintenancesXHR(data, dispatch));
      }
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getDroppableCalendarMaintenancesFail(error.message));
  }
};

export const getLiveMaintenancesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IMaintenance[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getLiveMaintenances(data.loading || "loading"));
    let response = await api.get<IApiResponse<IMaintenance[]>>(`maintenance/orders/by_status/v2`, {
      params: {
        status__in: "open,assigned,running,held,completed,requested",
        limit: 1000,
        offset: 0,
        order_by: data.queryParams?.order_by,
      },
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    if (response.data.results) {
      dispatch(dashboardSlice.actions.set_categoriesTypes(response.data.results));
    }
    dispatch(maintenanceSlice.actions.getLiveMaintenancesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getLiveMaintenancesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getLiveMaintenancesFail(error.message));
  }
};

export const writeReportXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IUserReport>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.writeReport());
    let response = await api.post<IApiResponse<IUserReport>>(
      `maintenance/report/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.writeReportSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => writeReportXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.writeReportFail(error.message));
  }
};

export const updateUserReportXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IUserReport>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.updateUserReport());
    let response = await api.put<IApiResponse<IUserReport>>(
      `maintenance/report/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.updateUserReportSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateUserReportXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.updateUserReportFail(error.message));
  }
};

export const addSingleTaskXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<ITask[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.addSingleTask());
    let response = await api.post<IApiResponse<ITask[]>>(
      `maintenance/task/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.addSingleTaskSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addSingleTaskXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.addSingleTaskFail(error.message));
  }
};

export const addTaskResulXHR = async (
  data: Partial<
    IDataToApi<{ result: string | string[] | number; task: number }, IApiResponse<ITask[]>>
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.addTaskResul(data.body?.task || 0));
    let response = await api.put<IApiResponse<ITask[]>>(
      `maintenance/task_result_update/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );

    console.log(response.data.results, "KK");

    dispatch(maintenanceSlice.actions.addTaskResulSuccess(response.data));

    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addTaskResulXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.addTaskResulFail(error.message));
  }
};

export const addCheckListToOrderXHR = async (
  data: Partial<IDataToApi<{ checklist: number; order_info: number }, IApiResponse<ITask[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.addCheckListToOrder());
    let response = await api.post<IApiResponse<ITask[]>>(
      `maintenance/add_checklist_to_order/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.addCheckListToOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addCheckListToOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.addCheckListToOrderFail(error.message));
  }
};

export const addExpenseXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IExpense>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(maintenanceSlice.actions.addExpense());
    let response = await api.post<IApiResponse<IExpense>>(`maintenance/expense/`, data.body, {
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.addExpenseSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addExpenseXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.addExpenseFail(error.message));
  }
};

export const getExpensesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IExpense[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(maintenanceSlice.actions.getExpenses());
    let response = await api.get<IApiResponse<IExpense[]>>(`maintenance/expense/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getExpensesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getExpensesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(maintenanceSlice.actions.getExpensesFail(error.message));
  }
};

export const editExpenseXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IExpense>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.editExpense());
    let response = await api.put<IApiResponse<IExpense>>(
      `maintenance/expense/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.editExpenseSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => editExpenseXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.editExpenseFail(error.message));
  }
};

export const setTaskRankXHR = async (
  data: Partial<
    IDataToApi<{ rank: number; task?: number }, IApiResponse<ITask[]>> & { url: string }
  >,
  dispatch: AppDispatch,
) => {
  //if url exsits that means that template tasks rank are updatenig
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.setTaskRank());
    let response: any = null;
    if (data.url) {
      response = await api.put<IApiResponse<ITask[]>>(
        data.url,
        data.body,

        { headers: { Authorization: "Bearer " + token } },
      );
    } else {
      response = await api.put<IApiResponse<ITask[]>>(
        `maintenance/task/${data.id}/`,
        data.body,

        { headers: { Authorization: "Bearer " + token } },
      );
    }

    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.setTaskRankSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => setTaskRankXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.setTaskRankFail(error.message));
  }
};

export const confirmOrderXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.confirmOrder());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/close_order/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.confirmOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => confirmOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.confirmOrderFail(error.message));
  }
};

export const updateMaintenanceXHR = async (
  data: Partial<IDataToApi<IUpdateMaintenance, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(maintenanceSlice.actions.updateMaintenance());
    let response = await api.put<IApiResponse<IMaintenanceDetails>>(
      `maintenance/orders/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.updateMaintenanceSuccess(response.data));
    getLiveMaintenancesXHR({ loading: "mqttSignalLoading" }, dispatch);
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateMaintenanceXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.updateMaintenanceFail(error.message));
  }
};

export const addWorkingTimeXHR = async (
  data: Partial<IDataToApi<IAddWorkingTime, IApiResponse<IFlow[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.addWorkingTime());
    let response = await api.post<IApiResponse<IFlow[]>>(
      `maintenance/working_time/add/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.addWorkingTimeSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addWorkingTimeXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.addWorkingTimeFail(error.message));
  }
};

export const getWorkCostsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IWorkCost[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getWorkCosts());
    let response = await api.get<IApiResponse<IWorkCost[]>>(
      `maintenance/orders/${data.id}/work_costs/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getWorkCostsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getWorkCostsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getWorkCostsFail(error.message));
  }
};

export const getTemplatesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ITemplate[]>> & { mergeData: boolean }>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getTemplates());
    let response = await api.get<IApiResponse<ITemplate[]>>(`maintenance/templates/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      maintenanceSlice.actions.getTemplatesSuccess({
        results: response.data.results,
        message: response.data.message,
        mergeData: data.mergeData || false,
      }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getTemplatesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getTemplatesFail(error.message));
  }
};

export const createTemplateXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<ITemplate>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.createTemplate());
    let response = await api.post<IApiResponse<ITemplate>>(
      `maintenance/templates/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.createTemplateSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createTemplateXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.createTemplateFail(error.message));
  }
};

export const getTemplateDetailsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ITemplateDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getTemplateDetails());
    let response = await api.get<IApiResponse<ITemplateDetails>>(
      `maintenance/templates/${data.id}/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getTemplateDetailsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getTemplateDetailsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getTemplateDetailsFail(error.message));
  }
};

export const updateTemplateDetailsXHR = async (
  data: Partial<
    IDataToApi<Partial<ITemplateForm & { created_at: string }>, IApiResponse<ITemplateDetails>>
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.updateTemplateDetails());
    let response = await api.put<IApiResponse<ITemplateDetails>>(
      `maintenance/templates/${data.id}/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.updateTemplateDetailsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateTemplateDetailsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.updateTemplateDetailsFail(error.message));
  }
};

export const createOrderFromTemplateXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.createOrderFromTemplate());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/templates/${data.id}/create_order/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.createOrderFromTemplateSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createOrderFromTemplateXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.createOrderFromTemplateFail(error.message));
  }
};

export const addExecutorsToTemplateXHR = async (
  data: Partial<
    IDataToApi<{ order_info: number; executors: IFormExecutors }, IApiResponse<ITemplateDetails>>
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.addExecutorsToTemplate());
    let response = await api.post<IApiResponse<ITemplateDetails>>(
      `maintenance/templates/${data.id}/add_executor/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.addExecutorsToTemplateSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addExecutorsToTemplateXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.addExecutorsToTemplateFail(error.message));
  }
};

export const addTemplateMaterialsXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IFile[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.addTemplateMaterials());
    let response = await api.post<IApiResponse<IFile[]>>(
      `maintenance/template_files/ `,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.addTemplateMaterialsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addTemplateMaterialsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.addTemplateMaterialsFail(error.message));
  }
};

export const addTaskToTemplateXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<ITask>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.addTaskToTemplate());
    let response = await api.post<IApiResponse<ITask>>(
      `maintenance/template_tasks/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.addTaskToTemplateSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addTaskToTemplateXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.addTaskToTemplateFail(error.message));
  }
};

export const getWorkingCostsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IWorkCostDetail[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getWorkingCosts());
    let response = await api.get<IApiResponse<IWorkCostDetail[]>>(
      `maintenance/orders/${data.id}/work_costs_details/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getWorkingCostsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getWorkingCostsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getWorkingCostsFail(error.message));
  }
};

export const getWorkRequestsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IWorkRequest[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getWorkRequests());
    let response = await api.get<IApiResponse<IWorkRequest[]>>(`settings/work_request/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getWorkRequestsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getWorkRequestsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getWorkRequestsFail(error.message));
  }
};

export const createWorkRequestXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IWorkRequest>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.createWorkRequest());
    let response = await api.post<IApiResponse<IWorkRequest>>(`settings/work_request/`, data.body);
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.createWorkRequestSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.createWorkRequestFail(error.message));
  }
};

export const updateOrerAssetXHR = async (
  data: Partial<
    IDataToApi<
      {
        order_info: number;
        asset: number | null;
      },
      IApiResponse<IMaintenanceDetails>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(maintenanceSlice.actions.getMaintenance("loading"));
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `/maintenance/update_order_asset/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(maintenanceSlice.actions.getMaintenanceSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateOrerAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(maintenanceSlice.actions.getMaintenanceFail(error.message));
  }
};

export const callLiveMaintenances = async () => {
  console.log(window.location.pathname, "PATHNAME");
  let tmp_: string | null = localStorage.getItem(LOCAL_STORAGE_MAINTENNACE_FILTERS_DASHBOARD);
  let filters: any = null;
  if (tmp_) {
    filters = JSON.parse(tmp_);
  } else {
    filters = {
      order_by: "-created_at",
    };
  }

  getLiveMaintenancesXHR(
    {
      loading: "mqttSignalLoading",
      queryParams: {
        order_by: filters?.order_by,
      },
    },
    store.dispatch,
  );
};
