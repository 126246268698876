import {
  Button,
  Tag as Chip,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import PreviewButton from "../../components/PreviewButton";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../../components/SelectTagAdd";
import SubAssetForm from "../../componentsform/SubAssetForm";
import { ITemplateForm } from "../../componentsform/TemplateForm";
import {
  LAYOUT_GRIDS,
  TEMPLATE_PERIODS,
  TEMPLATE_STATUSES,
  TRANSLATION_KEY,
} from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IApiResponse, ILayoutGrids, ILocation } from "../../models";
import { ISubAsset } from "../../models/asset";
import { IMaintenance } from "../../models/maintenances";
import api, { failedQueue, isRefreshing } from "../../services";
import { getSubAssetsXHR } from "../../store/reducers/asstes/actionCreators";
import { dashboardSlice } from "../../store/reducers/dashboard";
import { maintenanceSlice } from "../../store/reducers/maintenance";
import {
  createOrderFromTemplateXHR,
  getTemplateDetailsXHR,
  updateTemplateDetailsXHR,
} from "../../store/reducers/maintenance/actionCreator";
import { settingsSlice } from "../../store/reducers/settings";

import { hasPermission } from "../../helpers/functions";
import AssetModalPreview from "../assetdetails/components/AssetModalPreview";
import DurationInput from "../maintenance/components/DurationInput";
import TemplateExecutors from "./components/Executors";
import TemplateMaterials from "./components/Materials";
import Tasks from "./components/Tasks";

interface IProps {}

export type TepmplateStatus = "active" | "inactive";

const TemplateDetails: React.FC<IProps> = () => {
  // Hooks
  const { user } = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Variables
  let grids: ILayoutGrids = LAYOUT_GRIDS;
  const { id } = useParams();
  const [assetPreview, set_assetPreview] = useState<number>(-1);
  const [addLocationVisible, set_addLocationVisible] = useState(false);
  const [removeLoading, set_removeLoading] = useState(false);
  const [addSubAssetVisible, set_addSubAssetVisible] = useState<ISubAsset>();
  const {
    liveMaintenances,
    getTemplateDetailsStatus,
    templateDetails,
    maintenanceCategories,
    maintenancesTypes,
    updateTemplateDetailsStatus,
    getMaintenancesTypesStatus,
    getMaintenancesCategoriesStatus,
    createOrderFromTepmlateStatus,
  } = useAppSelector((state) => state.maintenanceReducer);
  const { subAssets } = useAppSelector((state) => state.assetReducer);
  const { locations, getLocationsStatus } = useAppSelector((state) => state.settingsReducer);

  // Methods
  useEffect(() => {
    getTemplateDetailsXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        id,
        successCallback: ({ results }) => {
          getSubAssetsXHR(
            {
              queryParams: {
                asset: results?.asset.id,
              },
              errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
            },
            dispatch,
          );
        },
      },
      dispatch,
    );
  }, []);

  const updateTemplate = (data: Partial<ITemplateForm & { created_at: string; every: number }>) => {
    updateTemplateDetailsXHR(
      {
        id: templateDetails?.id,
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        body: data,
      },
      dispatch,
    );
  };

  const removeTemplate = async () => {
    set_removeLoading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await api.delete<string>(`maintenance/templates/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      navigate("/app/templates");
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => removeTemplate());
        }
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_removeLoading(false);
  };

  function createOrderFromTemplate() {
    createOrderFromTemplateXHR(
      {
        id,
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: (data) => {
          if (data.results) {
            let arr: IMaintenance[] = [...liveMaintenances];
            arr.push(data.results);
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                message: "",
                results: arr,
              }),
            );
            dispatch(dashboardSlice.actions.set_categoriesTypes(arr));
          }
        },
      },
      dispatch,
    );
  }

  function disabledDate(current) {
    // Disable all dates that are before the current date but enable the current date
    return current && current < moment().startOf("day");
  }

  return (
    <div>
      {/* Page header */}
      <PageHeader
        style={{ padding: 0, paddingTop: 10 }}
        className="custom-page-header"
        onBack={() => navigate(-1)}
        subTitle={
          <div>
            <Typography.Text type="secondary">{t(TRANSLATION_KEY.template)} </Typography.Text>
            <Typography.Text strong>#{templateDetails?.number}</Typography.Text>
          </div>
        }
        extra={[
          <Popconfirm onConfirm={removeTemplate} title={t(TRANSLATION_KEY.continueWithAction)}>
            <Button type="primary" danger>
              {t(TRANSLATION_KEY.delete)}
            </Button>
          </Popconfirm>,
        ]}
      >
        <Space direction="vertical">
          <Space size="large" align="center" style={{ height: 44 }}>
            <Typography.Title
              level={3}
              editable={{
                triggerType: ["icon", "text"],
                onChange: (description) => {
                  if (!description) return;
                  updateTemplate({ description });
                },
              }}
            >
              {templateDetails?.description} {""}
            </Typography.Title>
          </Space>

          {/* Specifications */}
          <Descriptions bordered={false} column={3}>
            {/* Asset */}
            <Descriptions.Item label={t(TRANSLATION_KEY.asset)}>
              <PreviewButton
                isActive={templateDetails?.asset.is_active}
                title={templateDetails?.asset?.name}
                id={templateDetails?.asset?.id}
                url={`/app/asset-details/${templateDetails?.asset?.id}`}
                permissionsRequired={["view_asset"]}
                onClick={() => set_assetPreview(templateDetails?.asset?.id || -1)}
              />
            </Descriptions.Item>

            {/* Location */}
            <Descriptions.Item className={"select"} label={t(TRANSLATION_KEY.location)}>
              <Select
                bordered={false}
                allowClear
                style={{ width: "100%" }}
                showArrow={false}
                placeholder={t(TRANSLATION_KEY.selectLocation)}
                key={`location:${templateDetails?.location?.id}`}
                defaultValue={templateDetails?.location?.id}
                optionFilterProp="children"
                onChange={(location) => {
                  updateTemplate({ location: location || null });
                }}
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch={true}
                notFoundContent={
                  <ButtonAddSelectTag
                    addingVisible={addLocationVisible}
                    set_addingVisible={set_addLocationVisible}
                  />
                }
              >
                {locations.map((x) => (
                  <Select.Option value={x.id} key={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
                <Select.OptGroup
                  label={
                    <ButtonAddSelectTag
                      addingVisible={addLocationVisible}
                      set_addingVisible={set_addLocationVisible}
                    />
                  }
                />
              </Select>
            </Descriptions.Item>

            {/* Types */}
            <Descriptions.Item className={"select"} label={t(TRANSLATION_KEY.type)}>
              <Select
                bordered={false}
                style={{ width: "100%" }}
                showArrow={false}
                placeholder={t(TRANSLATION_KEY.selectType)}
                key={`maintenance_type:${templateDetails?.maintenance_type?.id}`}
                defaultValue={templateDetails?.maintenance_type?.id}
                onChange={(maintenance_type) => {
                  updateTemplate({ maintenance_type });
                }}
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch={true}
              >
                {maintenancesTypes.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {t(type.trans_key || type.name)}
                  </Select.Option>
                ))}
              </Select>
            </Descriptions.Item>

            {/* Status */}
            {/* <Descriptions.Item className={"select"} label={t(TRANSLATION_KEY.status)}>
              <Select
                bordered={false}
                style={{ width: "100%" }}
                showArrow={false}
                placeholder={t(TRANSLATION_KEY.selectStatus)}
                key={`status:${templateDetails?.status}`}
                defaultValue={t(templateDetails?.status || "")}
                onChange={(status) => {
                  updateTemplate({ status });
                }}
              >
                {STATUSES.filter((x) => x !== "cancelled").map((status) => (
                  <Select.Option key={status} value={status} disabled={status === "assigned"}>
                    {t(status)}
                  </Select.Option>
                ))}
              </Select>
            </Descriptions.Item> */}

            {/* Maintenance categories */}
            <Descriptions.Item className={"select"} label={t(TRANSLATION_KEY.category)}>
              <Select
                tagRender={({ label }) => <Chip className="tag-chip">{label}</Chip>}
                bordered={false}
                placeholder={t(TRANSLATION_KEY.selectCategory)}
                key={`maintenance_categories:${templateDetails?.maintenance_categories.map(
                  (item) => item.id,
                )}`}
                mode="tags"
                maxTagCount="responsive"
                defaultValue={templateDetails?.maintenance_categories.map((item) => item.id)}
                style={{ width: "100%" }}
                onChange={(maintenance_categories) => {
                  updateTemplate({ maintenance_categories });
                }}
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch={true}
              >
                {maintenanceCategories.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {t(category.trans_key || category.name)}
                  </Select.Option>
                ))}
              </Select>
            </Descriptions.Item>

            {/* Sub assets */}
            <Descriptions.Item className={"select"} label={t(TRANSLATION_KEY.assetMarks)}>
              <Select
                tagRender={({ label }) => <Chip className="tag-chip">{label}</Chip>}
                bordered={false}
                placeholder={t(TRANSLATION_KEY.assetMarks)}
                key={`subassets:${templateDetails?.subassets.map((item) => item.id)}`}
                mode="tags"
                maxTagCount="responsive"
                defaultValue={templateDetails?.subassets.map((item) => item.id)}
                style={{ width: "100%" }}
                onChange={(subassets) => {
                  updateTemplate({ subassets });
                }}
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch={true}
              >
                {subAssets.map((subAssets) => (
                  <Select.Option key={subAssets.id} value={subAssets.id}>
                    {subAssets.name}
                  </Select.Option>
                ))}

                {hasPermission(user?.account?.permissions || [], ["manage_asset"]) && (
                  <Select.OptGroup
                    label={
                      <ButtonAddSelectTag
                        addingVisible={!!addSubAssetVisible}
                        set_addingVisible={() =>
                          set_addSubAssetVisible({ id: 0, name: "", asset: { id: 0, name: "" } })
                        }
                      />
                    }
                  />
                )}
              </Select>
              <Modal
                visible={!!addSubAssetVisible}
                onCancel={() => {
                  set_addSubAssetVisible(undefined);
                }}
                footer={null}
                centered
                width="600px"
                closable
                destroyOnClose
                title={t(TRANSLATION_KEY.addAssetMark)}
              >
                <SubAssetForm
                  callback={(id: number) => {
                    if (!templateDetails) {
                      return;
                    }
                    updateTemplate({
                      subassets: [...templateDetails.subassets.map((x) => x.id), id],
                    });
                  }}
                  asset_id={templateDetails?.asset.id || 0}
                  close={() => set_addSubAssetVisible(undefined)}
                  subAsset={addSubAssetVisible}
                />
              </Modal>
            </Descriptions.Item>

            {/* Time needed */}
            <Descriptions.Item className={"select"} label={t(TRANSLATION_KEY.timeNeeded)}>
              <DurationInput
                disabled={false}
                defaultValue={moment.duration(templateDetails?.time_needed || "0:0:0")}
                onClick={(time_needed: string) => {
                  updateTemplate({ time_needed });
                }}
              />
            </Descriptions.Item>

            {/* Created by */}
            <Descriptions.Item label={t(TRANSLATION_KEY.createdBy)}>
              <Space>
                <Typography.Text strong>{templateDetails?.created_by.name}</Typography.Text>
                <Typography.Text>{moment(templateDetails?.created_at).format()}</Typography.Text>
              </Space>
            </Descriptions.Item>

            {/* Custom fields */}
            {templateDetails?.custom_fields.map((item, index) => (
              <Descriptions.Item key={index} label={item.name}>
                <Typography.Text
                  editable={{
                    triggerType: ["icon", "text"],
                    onChange: (text) => {
                      let custom_fields = [...templateDetails?.custom_fields!];
                      let updated_item = { ...item, value: text };
                      custom_fields[index] = updated_item;
                      updateTemplate({ custom_fields });
                    },
                  }}
                >
                  {item.value || "-"}
                </Typography.Text>
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Space>
      </PageHeader>

      <Divider />

      <Spin
        spinning={
          getTemplateDetailsStatus === "loading" ||
          updateTemplateDetailsStatus === "loading" ||
          getMaintenancesCategoriesStatus === "loading" ||
          getMaintenancesTypesStatus === "loading" ||
          getLocationsStatus === "loading" ||
          createOrderFromTepmlateStatus === "loading"
        }
      >
        <Row gutter={[24, 12]} id="maintenance-spec" style={{ marginBottom: 24 }}>
          {/* Trigger */}
          <Col md={24} lg={12}>
            <div className="white-container" style={{ height: "100%", paddingBottom: 10 }}>
              <div className="spaceBetweenRow">
                <Typography.Title level={5} style={{ paddingTop: 14 }}>
                  {t(TRANSLATION_KEY.trigger)}
                </Typography.Title>
                <Button type="link" onClick={createOrderFromTemplate}>
                  {t(TRANSLATION_KEY.now)}
                </Button>
              </div>
              <Divider style={{ marginTop: 16 }} />
              <Descriptions size="small" layout="horizontal" bordered>
                {/* Status */}
                <Descriptions.Item className={"select"} span={3} label={t(TRANSLATION_KEY.status)}>
                  <Select
                    placeholder={t(TRANSLATION_KEY.selectStatus)}
                    key={`maintenance_categories:${templateDetails?.maintenance_categories.map(
                      (item) => item.id,
                    )}`}
                    maxTagCount="responsive"
                    defaultValue={templateDetails?.template_status}
                    style={{ width: "100%" }}
                    onChange={(template_status) => {
                      updateTemplate({ template_status });
                    }}
                  >
                    {TEMPLATE_STATUSES.map((status) => (
                      <Select.Option key={status} value={status}>
                        {t(status)}
                      </Select.Option>
                    ))}
                  </Select>
                </Descriptions.Item>

                {/* Period */}
                <Descriptions.Item className={"select"} span={3} label={t(TRANSLATION_KEY.period)}>
                  <Select
                    placeholder={t(TRANSLATION_KEY.period)}
                    style={{ width: "100%" }}
                    value={templateDetails?.period}
                    onChange={(period) => {
                      updateTemplate({ period });
                    }}
                  >
                    {TEMPLATE_PERIODS.map((period) => (
                      <Select.Option key={period} value={period}>
                        {t(period)}
                      </Select.Option>
                    ))}
                  </Select>
                </Descriptions.Item>

                {/* Repeat every */}
                <Descriptions.Item span={3} label={t(TRANSLATION_KEY.repeatEvery)}>
                  <Typography.Text
                    editable={{
                      onChange: (text) => {
                        if (!text) return;
                        let tmp = +text;
                        if (!tmp || isNaN(tmp)) {
                          message.warning(t(TRANSLATION_KEY.wrongInput));
                          return;
                        }
                        updateTemplate({ every: tmp });
                      },
                    }}
                  >
                    {" "}
                    {templateDetails?.every}
                  </Typography.Text>
                </Descriptions.Item>

                {/* Next run */}
                <Descriptions.Item span={3} label={t(TRANSLATION_KEY.next_run)} className="select">
                  <DatePicker
                    key={`created_at:${templateDetails?.next_run}`}
                    value={
                      templateDetails?.next_run ? moment(templateDetails?.next_run) : undefined
                    }
                    style={{ width: "100%" }}
                    format={user.account.date_format || "DD.MM.YYYY - HH:mm"}
                    showTime={{
                      defaultValue: moment("08:00", "HH:mm"),
                    }}
                    disabledDate={disabledDate}
                    onChange={(date, created_at) => {
                      updateTemplate({
                        next_run: date?.set({ second: 0, millisecond: 0 }).toISOString(true),
                      });
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Col>

          {/* Executors */}
          <Col md={24} lg={12}>
            <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
              <TemplateExecutors template={templateDetails || null} />
            </div>
          </Col>
        </Row>
      </Spin>

      <Row gutter={[24, 12]}>
        {/* Tasks */}
        <Col md={24} lg={12}>
          {templateDetails ? (
            <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
              <Tasks template={templateDetails} />
            </div>
          ) : null}
        </Col>

        {/* Materials */}
        <Col md={24} lg={12}>
          <div className="white-container" style={{ height: "100%", paddingBottom: 10 }}>
            <TemplateMaterials template={templateDetails} />
          </div>
        </Col>
      </Row>

      {/* Add location tag */}
      <SelectTagAdd
        title={t(TRANSLATION_KEY.addLocation)}
        url="settings/locations/"
        set_visible={set_addLocationVisible}
        visible={addLocationVisible}
        successCallback={(tag: Tag) => {
          const data = [...locations];
          data.push({ id: tag.id, name: tag.name });
          const resFake: IApiResponse<ILocation[]> = {
            message: "",
            results: data,
          };

          dispatch(settingsSlice.actions.getLocationsSuccess(resFake));
          updateTemplate({ location: tag.id });
        }}
      />

      <Modal
        visible={assetPreview !== -1}
        onCancel={() => set_assetPreview(-1)}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AssetModalPreview id={templateDetails?.asset?.id} />
      </Modal>
    </div>
  );
};

export default TemplateDetails;
