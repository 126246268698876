import { message, Statistic, Card, Drawer, Row, Col } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getLiveMaintenancesXHR } from "../../store/reducers/maintenance/actionCreator";
import { FileSyncOutlined } from "@ant-design/icons";
import ConfirmOrder from "../maintenance/components/ConfirmOrder";
import CriticalQty from "../warehouse/components/CriticalQty";
import CostsByAsset from "./components/CostsByAsset";
import MaintenancesByCategory from "./components/MaintenancesByCategory";
import MaintenancesByType from "./components/MaintenancesByType";
import LiveMaintenances from "./components/LiveMaintenances";
import OrdersWithoutReprots from "./components/OrdersWithoutReprots";
import { motion } from "framer-motion";
import RequirePermission from "../../components/RequirePermission";
import { hasPermission } from "../../helpers/functions";

const DASHBOARD_GRIDS = {
  xs: 24,
  sm: 24,
  lg: 12,
  md: 12,
  xxl: 12,
  xl: 12,
  style: {
    padding: 12,
  },
};

type Filters = {
  order_by: string;
};

export const LOCAL_STORAGE_MAINTENNACE_FILTERS_DASHBOARD =
  "LOCAL_STORAGE_MAINTENNACE_FILTERS_DASHBOARD";

const Dashboard: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [confirmOrderDrawerVisible, set_confirmOrderDrawerVisible] = useState<boolean>(false);
  const { liveMaintenances } = useAppSelector((state) => state.maintenanceReducer);
  const [filters, set_filters] = useState<Filters>({
    order_by: "created_at",
  });
  const { user } = useAppSelector((state) => state.userReducer);

  const getMaintenances = (_filters: Filters) => {
    getLiveMaintenancesXHR(
      {
        errorCallback: (error) => {
          console.log(error);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback: () => {
          let tmpJson = JSON.stringify(_filters);
          localStorage.setItem(LOCAL_STORAGE_MAINTENNACE_FILTERS_DASHBOARD, tmpJson);
          set_filters(_filters);
        },
        loading: "loading",
        queryParams: {
          order_by: _filters?.order_by,
        },
      },
      dispatch,
    );
  };

  // Methods
  useEffect(() => {
    let tmpJson = localStorage.getItem(LOCAL_STORAGE_MAINTENNACE_FILTERS_DASHBOARD);
    let tmp: Filters | undefined = undefined;
    if (tmpJson) {
      tmp = JSON.parse(tmpJson);
    } else {
      tmp = filters;
    }

    if (!liveMaintenances.length) {
      tmp && getMaintenances(tmp);
    }
  }, []);

  return (
    <div style={{ paddingTop: 20 }}>
      {/* Buttons */}
      <Row gutter={[24, 24]}>
        {/* First card */}
        <Col xs={12} lg={8}>
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 10, opacity: 0 }}
            transition={{ ease: "anticipate", delay: 0, duration: 0.45 }}
            style={{ width: "100%" }}
          >
            <Card
              className="dashboard-card"
              onClick={() => set_confirmOrderDrawerVisible(true)}
              style={{ width: "100%", cursor: "pointer" }}
            >
              <Statistic
                title={t(TRANSLATION_KEY.maintenanceToClose)}
                value={liveMaintenances.filter((x) => x.status === "completed").length}
                prefix={<FileSyncOutlined />}
              />
            </Card>
          </motion.div>
        </Col>

        {/* Second card */}
        <Col xs={12} lg={8}>
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 10, opacity: 0 }}
            transition={{ ease: "anticipate", delay: 0.1, duration: 0.45 }}
            style={{ width: "100%" }}
          >
            <RequirePermission
              children={<OrdersWithoutReprots />}
              requiredPermissionCode={["manage_wo"]}
            />
          </motion.div>
        </Col>

        {/* Third card */}
        <Col xs={12} lg={8}>
          {hasPermission(user.account.permissions || [], ["manage_warehouse"]) && (
            <motion.div
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: 10, opacity: 0 }}
              transition={{ ease: "anticipate", delay: 0.2, duration: 0.45 }}
              style={{ width: "100%" }}
            >
              <CriticalQty />
            </motion.div>
          )}
        </Col>
      </Row>

      <Row gutter={[24, 24]} style={{ paddingTop: 20 }}>
        {/* Maintenances */}
        <Col span={24}>
          <div className="white-container" style={{ height: "100%", minHeight: 280 }}>
            <LiveMaintenances
              sorterValue={filters.order_by}
              onChangeSorter={(val) => {
                getMaintenances(val);
              }}
              maintenances={liveMaintenances}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={[24, 24]} style={{ paddingTop: 20 }}>
        {/* Categories */}
        <Col {...DASHBOARD_GRIDS}>
          <div className="white-container" style={{ height: "100%" }}>
            <MaintenancesByCategory />
          </div>
        </Col>

        {/* Costs */}
        <Col {...DASHBOARD_GRIDS}>
          <div className="white-container" style={{ height: "100%" }}>
            <MaintenancesByType />
          </div>
        </Col>
      </Row>

      <Row gutter={[24, 24]} style={{ paddingTop: 20 }}>
        <Col
          // {...DASHBOARD_GRIDS}
          span={24}
        >
          <RequirePermission
            children={
              <div className="white-container" style={{ height: "100%" }}>
                <CostsByAsset />
              </div>
            }
            requiredPermissionCode={["manage_analytics"]}
          />
        </Col>
        {/* Tehnicians */}
        <Col {...DASHBOARD_GRIDS}>
          {/* <div className="white-container" style={{ height: '100%' }}>
            <LoggedTechnicians />
          </div> */}
        </Col>
        {/* Types */}
      </Row>

      {/* Modal */}

      {/* Confirm orders */}
      <Drawer
        width={700}
        visible={confirmOrderDrawerVisible}
        onClose={() => set_confirmOrderDrawerVisible(false)}
        title={t(TRANSLATION_KEY.confirmOrders)}
        destroyOnClose={true}
      >
        <ConfirmOrder close={() => set_confirmOrderDrawerVisible(false)} />
      </Drawer>
    </div>
  );
};

export default Dashboard;
