import { DeleteOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Drawer,
  Form,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Typography,
} from "antd";
import { t } from "i18next";
import { useState } from "react";
import PreviewButton from "../../../components/PreviewButton";
import AddExecutorToOrder from "../../../componentsform/AddExecutorToOrder";
import { IFormExecutors } from "../../../componentsform/MaintenanceForm";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IExecutor, IMaintenanceDetails, ITemplateDetails } from "../../../models/maintenances";
import api, { failedQueue, isRefreshing } from "../../../services";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import {
  addExecutorsToTemplateXHR,
  getPotentialExecutorsXHR,
  removeExecutorFromOrderXHR,
} from "../../../store/reducers/maintenance/actionCreator";

import AccountModalPreview from "../../account/components/AccountModalPreview";
import SupplierModalPreview from "../../supplier/components/SupplierModalPreview";
import { store } from "../../../store";
import { IApiResponse } from "../../../models";

interface IProps {
  template: ITemplateDetails | null;
}

const Executors: React.FC<IProps> = ({ template }) => {
  console.log("🚧 - template:", template);
  const { removeExecutorFromOrderStatus, addExecutorsToTemplateStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  const [form] = Form.useForm();
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [delLoading, set_delLoading] = useState(-1);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [selectedSupplier, set_selectedSupplier] = useState<string | number | undefined>(undefined);

  //adding
  const [addExecutors, set_addExecutors] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const removeExecutor = async (executor: number) => {
    set_delLoading(executor);
    let token = await localStorage.getItem("token");
    try {
      let res = await api.post<IApiResponse<IMaintenanceDetails>>(
        `maintenance/templates/remove_executor/`,
        { executor },
        { headers: { Authorization: "Bearer " + token } },
      );
      if (template) {
        let tmp = [...template.executors];
        let index = tmp.findIndex((x) => x.id === executor);
        tmp.splice(index, 1);
        dispatch(
          maintenanceSlice.actions.getTemplateDetailsSuccess({
            message: "",
            results: {
              ...template,
              executors: tmp,
            },
          }),
        );
      } else {
        let tmpOrders = [...store.getState().maintenanceReducer.maintenanceList];
        let index = tmpOrders.findIndex((x) => x.id === res.data.results?.id);
        if (index !== -1 && res.data.results) {
          tmpOrders[index] = res.data.results;
          dispatch(
            maintenanceSlice.actions.getMaintenanceListSuccess({
              mergeData: false,
              message: "",
              results: tmpOrders,
            }),
          );
        }

        let tmLiveMaintenanceList = [...store.getState().maintenanceReducer.liveMaintenances];
        let indexLive = tmLiveMaintenanceList.findIndex((x) => x.id === res.data.results?.id);
        if (indexLive !== -1 && res.data.results) {
          tmLiveMaintenanceList[indexLive] = res.data.results;
          dispatch(
            maintenanceSlice.actions.getLiveMaintenancesSuccess({
              message: "",
              results: tmLiveMaintenanceList,
            }),
          );
        }

        let tmpMaintenanceDetails = { ...store.getState().maintenanceReducer.maintenanceDetails };
        if (tmpMaintenanceDetails?.id === res.data.results?.id) {
          dispatch(
            maintenanceSlice.actions.getMaintenanceSuccess({
              message: "",
              results: res.data.results,
            }),
          );
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => removeExecutor(executor));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_delLoading(-1);
  };

  const onAddExecutors = (executors: IFormExecutors) => {
    addExecutorsToTemplateXHR(
      {
        body: {
          order_info: template ? +template?.id : 0,
          executors,
        },
        id: template?.id,
        errorCallback: () => message.error(t(TRANSLATION_KEY.addExecutorFailed)),
        successCallback: (res) => {
          message.success(t(TRANSLATION_KEY.addExecutorSuccessful));
          set_addExecutors(false);
          form.resetFields();
        },
      },
      dispatch,
    );
  };

  const removeButton = (item: IExecutor) => {
    return (
      <Popconfirm
        cancelText={t(TRANSLATION_KEY.no)}
        okText={t(TRANSLATION_KEY.yes)}
        onCancel={() => {}}
        onConfirm={() => {
          // if (item.account) {
          removeExecutor(item.id);
          // } else {
          //   message.error(t(TRANSLATION_KEY.orderHasNoInCompanyExecutors));
          // }
        }}
        title={t(TRANSLATION_KEY.continueWithAction)}
      >
        <Button type="link" danger loading={delLoading === item.id}>
          {t(TRANSLATION_KEY.remove)}
        </Button>
      </Popconfirm>
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <List
            loading={removeExecutorFromOrderStatus === "loading"}
            header={
              <div className="spaceBetweenRow">
                <Typography.Title level={5} style={{ paddingTop: 4 }}>
                  {t(TRANSLATION_KEY.executors)}
                </Typography.Title>
                <Button
                  onClick={() => {
                    set_addExecutors(true);
                    getPotentialExecutorsXHR(
                      {
                        errorCallback: () => message.error(TRANSLATION_KEY.errorOnGetData),
                        queryParams: {
                          asset: template?.asset?.id || 0,
                          order_template: template?.id,
                        },
                      },
                      dispatch,
                    );
                  }}
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                />
              </div>
            }
            dataSource={template?.executors}
            renderItem={(item, index) => (
              <List.Item key={index} actions={[<div>{removeButton(item)}</div>]}>
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  description={
                    item.account ? item.account.phone_number : item.supplier?.phone_number
                  }
                  title={
                    <Space>
                      {/* Informations */}
                      <Space direction="vertical">
                        <Space>
                          <Typography.Text strong>
                            #{item.account ? item.account.custom_id : item.supplier?.custom_id}
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            {item.account
                              ? item.account.role?.name
                              : item.supplier
                                ? item.supplier.category?.name
                                : null}
                          </Typography.Text>
                        </Space>
                        <Space>
                          {/* Is working badge */}
                          {item.is_working && <Badge status="success" />}
                          {/* Preview button */}
                          <PreviewButton
                            isActive={
                              item.account ? item.account.is_active : item.supplier?.is_active
                            }
                            title={item.account ? item.account.name : item.supplier?.name}
                            id={item.account ? item.account.id : item.supplier?.id}
                            url={
                              item.account
                                ? `/app/humanresources/${item.account.id}`
                                : item.supplier
                                  ? `/app/suppliers/${item.supplier.id}`
                                  : ""
                            }
                            onClick={() => {
                              if (item.account) {
                                set_selectedAccount(item.account?.id);
                              } else if (item.supplier) {
                                set_selectedSupplier(item.supplier?.id);
                              }
                              set_modalVisible(true);
                            }}
                          />
                        </Space>
                      </Space>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>

      <Drawer
        title={t(TRANSLATION_KEY.addExecutors)}
        visible={addExecutors}
        onClose={() => set_addExecutors(false)}
        destroyOnClose={true}
      >
        {addExecutors && (
          <AddExecutorToOrder
            order_info={undefined}
            asset_id={template?.asset?.id || 0}
            onAdd={onAddExecutors}
            loading={addExecutorsToTemplateStatus === "loading"}
            skipGetPotentialExecutors // skip because getPotentialExecutors request is called on button click
          />
        )}
      </Drawer>

      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedAccount(undefined);
          set_selectedSupplier(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
        {selectedSupplier && <SupplierModalPreview id={selectedSupplier} />}
      </Modal>
    </>
  );
};

export default Executors;
