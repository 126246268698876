import React, { useEffect, useState } from "react";

// Antd
import {
  Button,
  Drawer,
  Input,
  message,
  Modal,
  Space,
  Table,
  TableColumnType,
  Tooltip,
  TreeSelect,
  Typography,
} from "antd";
import styles from "./styles.module.css";

// Rest
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { ICursor, ITableColumn } from "../../../models";
import { IPart, IPartsList, IPartStorage } from "../../../models/parts";
import {
  createTreeSelect,
  debounce,
  parseCosts,
  setItemsWithoutParentToHighestNode,
} from "../../../helpers/functions";
import LoadMoreButton from "../../../components/LoadMoreButton";
import {
  BulbOutlined,
  DiffOutlined,
  FileExcelFilled,
  FileExcelOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getAssetXHR } from "../../../store/reducers/asstes/actionCreators";
import { ILocalFilters } from "../pages/AllParts";
import SpendPart from "../../../componentsform/SpendPart";
import PreviewButton from "../../../components/PreviewButton";
import PartModalPreview from "../../part/components/PartModalPreview";
import AddPartToStorage from "../../../componentsform/AddPartToStorage";
import { warehouseSlice } from "../../../store/reducers/warehouse";
import { RootState } from "../../../store";
import { connect } from "react-redux";
import { IUser, TableView } from "../../../models/user";
import { IAsset } from "../../../models/asset";
import ViewTabs, {
  ColumnFilterType,
  CustomTableColumn,
  customTableColumnRender,
  filterColumns,
  getFilterComponent,
  saveTableDefinition,
} from "../../../components/ViewTabs";
import Columns from "../../../components/ViewTabs/Columns";

// Constants
const STORAGE_COLUMNS_NAME = "MaintenancesTable";

// Props
interface IProps {
  searchByInfo: string;
  data: IPartsList;
  loading: boolean;
  filters: ILocalFilters;
  setFilters: (f: ILocalFilters) => void;
  onLoadMore: () => void;
  getParts: (filters: ILocalFilters) => void;
  user: IUser;
  exportToExcel: () => void;
  setAssetManagment: (part: IPart, asset: IAsset[]) => void;
  exportLoading: boolean;
  cursor: ICursor; // potrebno za update
}

type TableRenders =
  | "PartName"
  | "PartCode"
  | "PartDescription"
  | "PartCategory"
  | "PartTotalQty"
  | "PartForAssets"
  | "PartCriticalQty"
  | "PartAvgPrice"
  | "PartTotalPrice"
  | "PartActions";

const PartsTable: React.FC<IProps> = ({
  searchByInfo,
  getParts,
  data,
  loading,
  onLoadMore,
  filters,
  setFilters,
  exportToExcel,
  setAssetManagment,
  exportLoading,
  cursor,
  user,
}) => {
  // Hooks
  const dispatch = useAppDispatch();
  const [drawerVisible, set_drawerVisible] = useState<IPart>();
  const [spendPartWithoutOrder, set_spendPartWithoutOrder] = useState<IPart>();
  const [selectedPart, setSelectedPart] = useState<number | undefined>(undefined);
  const [preview, set_preview] = useState<boolean>(false);
  const { assetList } = useAppSelector((state) => state.assetReducer);

  useEffect(() => {
    if (firstMount) {
      set_firstMount(false);
      return;
    }
    let tmpDefinitions =
      user.account.views?.items?.map((x, i) => ({
        ...x,
        updated: new Date().getTime(),
        active: i === 0,
      })) || [];
    set_tableDefinition(tmpDefinitions);
  }, [user]);

  const [firstMount, set_firstMount] = useState(true);

  const [tableDefinition, set_tableDefinition] = useState<
    Array<
      TableView & {
        updated: number;
        active: boolean;
      }
    >
  >(
    user?.account.views?.items?.map((x, i) => ({
      ...x,
      updated: new Date().getTime(),
      active: i === 0,
    })) || [],
  );

  const [COLUMNS, set_COLUMNS] = React.useState<
    Array<Partial<CustomTableColumn> & TableColumnType<IPart>>
  >([]);

  useEffect(() => {
    set_COLUMNS([...COLUMNS]);
  }, [data.data, filters]);

  useEffect(() => {
    let activeView = tableDefinition.find((x) => x.active);
    if (activeView) {
      setColumns(activeView.table_structure, 0);
    }
    getAssetXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  const setColumns = (_c: CustomTableColumn[], viewIndex: number) => {
    // ostaje unutar komponente za koju se prave views-ovi iz razloga jer će trebati filtere okidati
    let tmp: Array<CustomTableColumn & TableColumnType<IPart>> = [];
    _c.forEach((c) => {
      tmp.push({
        ...c,
        title: t(c.title),
        render: c.columnRenderComponent
          ? render(c.columnRenderComponent as TableRenders)
          : customTableColumnRender(
              c.filterComponent as ColumnFilterType,
              c.dataIndex,
              user,
              "custom_fields_v2",
            ),
        onCell: (record: IPart) => {
          return { rowSpan: 1 };
        },
        filterDropdown: undefined,
      });
    });
    set_COLUMNS(tmp);
  };

  const render = (type: TableRenders) => {
    switch (type) {
      case "PartName":
        return (text: string, value: IPart) => {
          return (
            <PreviewButton
              isActive={value.is_active}
              onClick={() => {
                setSelectedPart(value.id);
                set_preview(true);
              }}
              title={value.name}
              id={value.id}
              url={`/app/item-details/${value.id}/`}
            />
          );
        };
      case "PartCode":
        return (text: string, value: IPart) => {
          return <span>{value.code}</span>;
        };
      case "PartDescription":
        return (text: string, value: IPart) => {
          return <span>{value.description}</span>;
        };
      case "PartCategory":
        return (text: string, value: IPart) => {
          return <span>{value.category?.name || "-"}</span>;
        };
      case "PartTotalQty":
        return (text: string, value: IPart) => {
          let sum = 0;
          value.part_storage.forEach((x) => {
            if (
              filters.warehouse.length > 0 &&
              filters.warehouse.includes(x.storage.id.toString())
            ) {
              sum += +x.qty;
            } else if (filters.warehouse.length === 0) {
              sum += +x.qty;
            }
          });
          return (
            <div style={{ width: "max-content", display: "flex" }}>
              <span>{`${sum} ${t(value.uom)}`}</span>{" "}
            </div>
          );
        };
      case "PartForAssets":
        return (text: string, value: IPart) => (
          <Typography.Text className="ellipsis" style={{ maxWidth: "18vh", display: "block" }}>
            {value.assets?.map((x) => x.name).join(", ") || "-"}
          </Typography.Text>
        );
      case "PartCriticalQty":
        return (text: string, value: IPart) => (
          <Typography.Text>{value.critical_qty || "-"}</Typography.Text>
        );
      case "PartAvgPrice":
        return (text: string, value: IPart) => (
          <span>
            {parseCosts(value.avg_price ? value.avg_price.toString() : "0")}{" "}
            {user?.account.company.currency}
          </span>
        );
      case "PartTotalPrice":
        return (text: string, value: IPart) => (
          <span>
            {parseCosts(value.total_price ? value.total_price.toString() : "0")}{" "}
            {user?.account.company.currency}
          </span>
        );
      case "PartActions":
        return (text, value) => (
          <Space>
            <Tooltip title={t(TRANSLATION_KEY.addPartToStorage)}>
              <Button onClick={() => set_drawerVisible(value)} icon={<PlusOutlined />} />
            </Tooltip>
            <Tooltip title={t(TRANSLATION_KEY.spendPart)}>
              <Button
                disabled={value.part_storage.length < 1}
                onClick={() => set_spendPartWithoutOrder(value)}
                icon={<DiffOutlined />}
              />
            </Tooltip>
            <Tooltip title={t(TRANSLATION_KEY.assignAssets)}>
              <Button
                onClick={() => {
                  setAssetManagment(value, value.assets || []);
                }}
                icon={<BulbOutlined />}
              />
            </Tooltip>
          </Space>
        );
    }
  };

  let warehouse = assetList.filter((x) => x.type === "wrh");

  warehouse = warehouse.map((x) => ({
    ...x,
    parent_id: x.parent_id
      ? warehouse.some((y) => y.id === x.parent_id)
        ? x.parent_id
        : null
      : null,
  }));

  let connectedAssetTree = createTreeSelect(
    setItemsWithoutParentToHighestNode(assetList).map((x) => ({
      title: x.name,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  let selectTreeData = createTreeSelect(
    setItemsWithoutParentToHighestNode(warehouse).map((x) => ({
      title: x.name,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  const _getParts = debounce<typeof getParts>(getParts, 600);

  return (
    <>
      {/* Header */}
      <div className={styles.headerContainer}>
        {/* Columns */}
        <div className={styles.headerFlexSpaceBetween} style={{ marginRight: 12 }}></div>

        <div className={styles.headerFlexSpaceBetween}>
          <div className={styles.headerFlexSpaceBetween}>
            <Button
              onClick={exportToExcel}
              style={{
                marginRight: 12,
              }}
              loading={exportLoading}
              icon={<FileExcelOutlined />}
            >
              {t(TRANSLATION_KEY.export)}
            </Button>
            <TreeSelect
              onClear={() => {
                getParts({ ...filters, asset: [] });
              }}
              onChange={(val: string[]) => {
                getParts({ ...filters, asset: val });
              }}
              multiple
              allowClear
              placeholder={t(TRANSLATION_KEY.connectedToParts)}
              style={{ minWidth: 240, marginRight: 12 }}
              treeData={connectedAssetTree}
              showSearch
              filterTreeNode={(search, item: any) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
              }}
            />
            <TreeSelect
              onClear={() => {
                getParts({ ...filters, warehouse: [] });
              }}
              onSelect={(a: unknown) => {
                let tmpArr = [...filters.warehouse];
                if (typeof a === "string") {
                  tmpArr.push(a);
                }
                getParts({ ...filters, warehouse: tmpArr });
              }}
              onDeselect={(a: unknown) => {
                let tmpArr = [...filters.warehouse];
                let index = tmpArr.findIndex((x) => x === a);
                if (index !== -1) {
                  tmpArr.splice(index, 1);
                }
                getParts({ ...filters, warehouse: tmpArr });
              }}
              multiple
              allowClear
              placeholder={t(TRANSLATION_KEY.selectWarehouse)}
              style={{ minWidth: 240, marginRight: 12 }}
              treeData={selectTreeData}
              showSearch
              filterTreeNode={(search, item: any) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
              }}
            />
            {/* Info */}

            {/* Search */}
            <Input.Search
              onChange={({ target: { value } }) => {
                let tmp: ILocalFilters = {
                  ...filters,
                  search: value.toLowerCase(),
                };
                _getParts(tmp);
              }}
              allowClear
              style={{ marginRight: 18, marginLeft: 12, maxWidth: 320 }}
            />
            <Tooltip title={searchByInfo}>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </div>
      </div>

      <div>
        <ViewTabs
          onChange={(activeKey) => {
            let index = tableDefinition.findIndex((view) => view.name === activeKey);
            let tmpList = [...tableDefinition];
            if (index === -1) {
              message.error("view_onSaveColumnsDefinition");
              return;
            }
            tmpList = tmpList.map((x) => ({ ...x, active: false }));
            tmpList[index] = { ...tmpList[index], active: true };
            getParts(filters);
            setColumns(tmpList[index].table_structure, index);
            set_tableDefinition(tmpList);
          }}
          setColumns={setColumns}
          views={tableDefinition}
          viewCategory="items"
        />
      </div>
      <div style={{ marginBottom: 12 }}>
        <Columns
          columns={COLUMNS}
          set_COLUMNS={(columns) => {
            let index = tableDefinition.findIndex((view) => view.active === true);
            if (index === -1) {
              message.error("view");
              return;
            }
            let tmp = tableDefinition[index];
            if (!tmp) {
              return;
            }

            let inerTmp: CustomTableColumn[] = [];
            columns.forEach((x) => {
              inerTmp.push({
                title: x.title || "",
                dataIndex: x.dataIndex || "",
                visible: x.visible === undefined ? 1 : x.visible,
                onCellFlag: x.onCellFlag || false,
                filterComponent: x.filterComponent || null,
                columnRenderComponent: x.columnRenderComponent || null,
                objectKey: x.objectKey || null,
              });
            });
            tmp = { ...tmp, table_structure: inerTmp, updated: Date.now(), active: true };
            let tmpTableDefinition = [...tableDefinition].map((x) => ({ ...x, active: false }));
            tmpTableDefinition[index] = tmp;
            setColumns(inerTmp, index);
            set_tableDefinition(tmpTableDefinition);
          }}
        />
        <Button
          style={{ marginLeft: 18 }}
          type="link"
          onClick={() => {
            let activeView = tableDefinition.find((x) => x.active);
            saveTableDefinition(
              tableDefinition,
              user.account.views?.items?.find((x) => x.id === activeView?.id),
              "items",
            );
          }}
        >
          {t(TRANSLATION_KEY.saveChanges)}
        </Button>
      </div>
      {/* Table */}
      <Table
        size="small"
        loading={loading}
        dataSource={data.data}
        columns={filterColumns<IPart>(COLUMNS)}
        rowKey={(item: IPart) => item.id}
        pagination={false}
      />

      {/* Load more button */}
      {data.cursor.next && <LoadMoreButton loading={loading} onClick={onLoadMore} />}

      <Modal
        destroyOnClose
        visible={!!spendPartWithoutOrder}
        footer={null}
        title={t(TRANSLATION_KEY.spendPart)}
        onCancel={() => set_spendPartWithoutOrder(undefined)}
      >
        <SpendPart
          cursor={cursor}
          type="parts_list"
          part={spendPartWithoutOrder}
          partStorage={null}
          close={() => set_spendPartWithoutOrder(undefined)}
        />
      </Modal>

      {/* Modal */}
      <Modal
        visible={preview}
        onCancel={() => {
          set_preview(false);
        }}
        footer={null}
        centered
        width="800px"
        closable={false}
        destroyOnClose
      >
        <PartModalPreview user={user} id={selectedPart} />
      </Modal>
      <Drawer
        visible={!!drawerVisible}
        width={540}
        title={t(TRANSLATION_KEY.addPartToStorage)}
        onClose={() => set_drawerVisible(undefined)}
        destroyOnClose={true}
      >
        {!!drawerVisible && (
          <AddPartToStorage
            user={user}
            cursor={cursor}
            part={drawerVisible}
            close={() => set_drawerVisible(undefined)}
          />
        )}
      </Drawer>
    </>
  );
};

export default PartsTable;
