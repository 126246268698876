import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFile } from "../../../components/Files";
import { IApiResponse, ICursor } from "../../../models";
import {
  IExpense,
  IMaintenance,
  IMaintenanceCategory,
  IMaintenanceDetails,
  IMaintenanceList,
  IMaintenanceOnHoldType,
  IMaintenanceType,
  IUserReport,
  IPotentialExecutors,
  IFlow,
  IWorkCost,
  ITemplate,
  ITemplateDetails,
  IWorkCostDetail,
  IWorkRequest,
} from "../../../models/maintenances";
import { ITask } from "../../../models/settings";

interface IMaintenanceReducer {
  createWorkRequestStatus: string;
  getWorkRequestsStatus: string;
  workRequests: IWorkRequest[];
  workingCostsDetail: IWorkCostDetail[];
  getWorkingCostsStatus: string;
  addTaskToTemplateStatus: string;
  addTemplateMaterialsStatus: string;
  addExecutorsToTemplateStatus: string;
  updateTemplateDetailsStatus: string;
  templateDetails: ITemplateDetails | null;
  getTemplateDetailsStatus: string;
  createTemplateStatus: string;
  templates: ITemplate[];
  getTemplatesStatus: string;
  workCosts: IWorkCost[];
  getWorkCostsStatus: string;
  setTaskRankStatus: string;
  getMaintenancesCategoriesStatus: string;
  maintenanceCategories: IMaintenanceCategory[];
  getMaintenancesTypesStatus: string;
  maintenancesTypes: IMaintenanceType[];
  getMaintenanceListStatus: string;
  maintenanceList: IMaintenanceList;
  createFullOrderStatus: string;
  getPotentialExecutorsStatus: string;
  potentialExecutors: IPotentialExecutors;
  getMaintenanceStatus: string;
  maintenanceDetails: IMaintenanceDetails;
  takeMaintenanceStatus: string;
  startMaintenanceStatus: string;
  onHoldMaintenanceStatus: string;
  getStopWorkingReasonsStatus: string;
  onHoldTypes: IMaintenanceOnHoldType[];
  finishMaintenanceStatus: string;
  removeExecutorFromOrderStatus: string;
  addExecutorsFromOrderStatus: string;
  moveMaintenanceToOpenStatus: string;
  calendarMaintenances: IMaintenance[];
  getCalendarMaintenancesStatus: string;
  droppableCalendarMaintenances: IMaintenance[];
  getDroppableCalendarMaintenancesStatus: string;
  cancelMaintenanceStatus: string;
  getLiveMaintenancesStatus: string;
  liveMaintenances: IMaintenance[];
  writeReportStatus: string;
  updateUserReportStatus: string;
  addSingleTaskStatus: string;
  addTaskResulStatus: string;
  addCheckListToOrderStatus: string;
  addExpenseStatus: string;
  expenses: IExpense[];
  getExpensesStatus: string;
  editExpenseStatus: string;
  confirmOrderStatus: string;
  updateMaintenanceStatus: string;
  addWorkingTimeStatus: string;
  createOrderFromTepmlateStatus: string;
}

const initialState: IMaintenanceReducer = {
  createWorkRequestStatus: "",
  getWorkRequestsStatus: "",
  workRequests: [],
  workingCostsDetail: [],
  getWorkingCostsStatus: "",
  orders_without_report: [],
  addTaskToTemplateStatus: "",
  addTemplateMaterialsStatus: "",
  addExecutorsToTemplateStatus: "",
  updateTemplateDetailsStatus: "",
  templateDetails: null,
  getTemplateDetailsStatus: "",
  createTemplateStatus: "",
  templates: [],
  getTemplatesStatus: "",
  workCosts: [],
  getWorkCostsStatus: "",
  setTaskRankStatus: "",
  getMaintenancesCategoriesStatus: "",
  maintenanceCategories: [],
  getMaintenancesTypesStatus: "",
  maintenancesTypes: [],
  getMaintenanceListStatus: "",
  droppableCalendarMaintenances: [],
  getDroppableCalendarMaintenancesStatus: "",
  maintenanceList: [],
  createFullOrderStatus: "",
  getPotentialExecutorsStatus: "",
  potentialExecutors: {
    suppliers: [],
    employees: [],
    partners: [],
  },
  getMaintenanceStatus: "loading",
  maintenanceDetails: {
    client: null,
    rent_order: null,
    status_change_ts: "",
    work_request: null,
    subassets: [],
    flows: [],
    closed_by: null,
    closed_at: "",
    reports: [],
    custom_fields: [],
    id: 0,
    description: "",
    location: null,
    planned_start: null,
    planned_end: null,
    real_start: "",
    real_end: null,
    status: "",
    time_needed: null,
    working_time: "",
    is_late: false,
    order_number: "",
    created_at: "",
    schedule: null,
    deadline: "",
    maintenance_categories: [],
    duration: "",
    reported_by: {
      topic: "",
      id: 0,
      email: "",
      name: "",
      phone_number: "",
      note: "",
      created_at: "",
      avatar: "",
      currency: "",
      price_per_hour: 0,
      custom_id: null,
      date_format: null,
      is_active: false,
      accepted_terms: true,
      custom_fields_v2: {},
      role: {
        id: 0,
        name: "",
      },
      company: {
        id: 0,
        id_pdv: "",
        name: "",
        email: "",
        phone_number: "",
        address: "",
        web: "",
        topic: "",
      },
    },
    executors: [],
    search: "",
    cheklist: [],
    number: "",
    tasks: [],
    asset: null,
    maintenance_type: null,
    files: [],
    parts: [],
    held_reason: null,
    is_active: false,
  },
  takeMaintenanceStatus: "",
  startMaintenanceStatus: "",
  onHoldMaintenanceStatus: "",
  getStopWorkingReasonsStatus: "",
  onHoldTypes: [],
  finishMaintenanceStatus: "",
  removeExecutorFromOrderStatus: "",
  addExecutorsFromOrderStatus: "",
  moveMaintenanceToOpenStatus: "",
  calendarMaintenances: [],
  getCalendarMaintenancesStatus: "",
  cancelMaintenanceStatus: "",
  getLiveMaintenancesStatus: "",
  liveMaintenances: [],
  writeReportStatus: "",
  updateUserReportStatus: "",
  addSingleTaskStatus: "",
  addTaskResulStatus: "",
  addCheckListToOrderStatus: "",
  addExpenseStatus: "",
  expenses: [],
  getExpensesStatus: "",
  editExpenseStatus: "",
  confirmOrderStatus: "",
  updateMaintenanceStatus: "",
  addWorkingTimeStatus: "",
  createOrderFromTepmlateStatus: "",
} as IMaintenanceReducer;

export const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    getMaintenancesCategories(state) {
      state.getMaintenancesCategoriesStatus = "loading";
    },
    getMaintenancesCategoriesSuccess(
      state,
      action: PayloadAction<IApiResponse<IMaintenanceCategory[]>>,
    ) {
      state.getMaintenancesCategoriesStatus = "";
      if (action.payload.results) {
        state.maintenanceCategories = action.payload.results;
      }
    },
    getMaintenancesCategoriesFail(state, action: PayloadAction<string>) {
      state.getMaintenancesCategoriesStatus = action.payload;
    },

    // -----

    getMaintenancesTypes(state) {
      state.getMaintenancesTypesStatus = "loading";
    },
    getMaintenancesTypesSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceType[]>>) {
      state.getMaintenancesTypesStatus = "";
      if (action.payload.results) {
        state.maintenancesTypes = action.payload.results;
      }
    },
    getMaintenancesTypesFail(state, action: PayloadAction<string>) {
      state.getMaintenancesTypesStatus = action.payload;
    },
    // -----

    getMaintenanceList(state) {
      state.getMaintenanceListStatus = "loading";
    },
    getMaintenanceListSuccess(
      state,
      action: PayloadAction<IApiResponse<IMaintenanceList> & { mergeData: boolean }>,
    ) {
      if (action.payload.results) {
        let tmp: IMaintenance[] = [];
        if (action.payload.mergeData) {
          tmp = [...state.maintenanceList, ...action.payload.results];
        } else {
          tmp = action.payload.results;
        }
        state.maintenanceList = tmp;
      }
      state.getMaintenanceListStatus = "";
    },
    getMaintenanceListFail(state, action: PayloadAction<string>) {
      state.getMaintenanceListStatus = action.payload;
      state.maintenanceList = state.maintenanceList;
    },
    clearMaintenanceList(state) {
      state.maintenanceList = [];
    },

    // -----

    createFullOrder(state) {
      state.createFullOrderStatus = "loading";
    },
    createFullOrderSuccess(state, action: PayloadAction<IApiResponse<IMaintenance>>) {
      if (action.payload.results) {
        let _tmpAfterAdd = {
          maintenanceList: [...state.maintenanceList],
        };
        _tmpAfterAdd.maintenanceList.unshift(action.payload.results);
      }
      state.createFullOrderStatus = "";
    },
    createFullOrderFail(state, action: PayloadAction<string>) {
      state.createFullOrderStatus = action.payload;
    },

    // -----

    getPotentialExecutors(state) {
      state.getPotentialExecutorsStatus = "loading";
    },
    getPotentialExecutorsSuccess(state, action: PayloadAction<IApiResponse<IPotentialExecutors>>) {
      if (action.payload.results) {
        state.potentialExecutors = action.payload.results;
      }
      state.getPotentialExecutorsStatus = "";
    },
    getPotentialExecutorsFail(state, action: PayloadAction<string>) {
      state.getPotentialExecutorsStatus = action.payload;
    },

    // -----

    getMaintenance(state, action: PayloadAction<string>) {
      state.getMaintenanceStatus = action.payload;
    },
    getMaintenanceSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        state.maintenanceDetails = action.payload.results;
      }
      state.getMaintenanceStatus = "";
    },
    getMaintenanceFail(state, action: PayloadAction<string>) {
      state.getMaintenanceStatus = action.payload;
    },

    // -----

    takeMaintenance(state) {
      state.takeMaintenanceStatus = "loading";
    },
    takeMaintenanceSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        state.maintenanceDetails = action.payload.results;
      }
      state.takeMaintenanceStatus = "";
    },
    takeMaintenanceFail(state, action: PayloadAction<string>) {
      state.takeMaintenanceStatus = action.payload;
    },
    // -----

    startMaintenance(state) {
      state.startMaintenanceStatus = "loading";
    },
    startMaintenanceSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        state.maintenanceDetails = action.payload.results;
      }
      state.startMaintenanceStatus = "";
    },
    startMaintenanceFail(state, action: PayloadAction<string>) {
      state.startMaintenanceStatus = action.payload;
    },

    // -----

    onHoldMaintenance(state) {
      state.onHoldMaintenanceStatus = "loading";
    },
    onHoldMaintenanceSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        let tmp = [...state.liveMaintenances];
        let index = tmp.findIndex((item) => item.id === action.payload.results?.id);
        if (index !== -1) {
          tmp[index] = action.payload.results;
        }
        state.liveMaintenances = tmp;
        state.maintenanceDetails = action.payload.results;
      }
      state.onHoldMaintenanceStatus = "";
    },
    onHoldMaintenanceFail(state, action: PayloadAction<string>) {
      state.onHoldMaintenanceStatus = action.payload;
    },

    // -----

    getStopWorkingReasons(state) {
      state.getStopWorkingReasonsStatus = "loading";
    },
    getStopWorkingReasonsSuccess(
      state,
      action: PayloadAction<IApiResponse<IMaintenanceOnHoldType[]>>,
    ) {
      if (action.payload.results) {
        state.onHoldTypes = action.payload.results;
      }
      state.getStopWorkingReasonsStatus = "";
    },
    getStopWorkingReasonsFail(state, action: PayloadAction<string>) {
      state.getStopWorkingReasonsStatus = action.payload;
    },

    // -----

    finishMaintenance(state) {
      state.finishMaintenanceStatus = "loading";
    },
    finishMaintenanceSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        let tmp = [...state.liveMaintenances];
        let index = tmp.findIndex((item) => item.id === action.payload.results?.id);
        if (index !== -1) {
          tmp.splice(index, 1);
        }
        state.maintenanceDetails = action.payload.results;
        state.liveMaintenances = tmp;
      }
      state.finishMaintenanceStatus = "";
    },
    finishMaintenanceFail(state, action: PayloadAction<string>) {
      state.finishMaintenanceStatus = action.payload;
    },
    // -----

    removeExecutorFromOrder(state) {
      state.removeExecutorFromOrderStatus = "loading";
    },
    removeExecutorFromOrderSuccess(
      state,
      action: PayloadAction<IApiResponse<IMaintenanceDetails>>,
    ) {
      if (action.payload.results) {
        state.maintenanceDetails = action.payload.results;
      }
      state.removeExecutorFromOrderStatus = "";
    },
    removeExecutorFromOrderFail(state, action: PayloadAction<string>) {
      state.removeExecutorFromOrderStatus = action.payload;
    },

    // -----

    addExecutorsFromOrder(state) {
      state.addExecutorsFromOrderStatus = "loading";
    },
    addExecutorsFromOrderSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        state.maintenanceDetails = action.payload.results;
      }
      state.addExecutorsFromOrderStatus = "";
    },
    addExecutorsFromOrderFail(state, action: PayloadAction<string>) {
      state.addExecutorsFromOrderStatus = action.payload;
    },
    // -----

    moveMaintenanceToOpen(state) {
      state.moveMaintenanceToOpenStatus = "loading";
    },
    moveMaintenanceToOpenSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        state.maintenanceDetails = action.payload.results;
      }
      state.moveMaintenanceToOpenStatus = "";
    },
    moveMaintenanceToOpenFail(state, action: PayloadAction<string>) {
      state.moveMaintenanceToOpenStatus = action.payload;
    },

    // -----

    getCalendarMaintenances(state) {
      state.getCalendarMaintenancesStatus = "loading";
    },
    getCalendarMaintenancesSuccess(state, action: PayloadAction<IApiResponse<IMaintenance[]>>) {
      if (action.payload.results) {
        state.calendarMaintenances = action.payload.results;
      }
      state.getCalendarMaintenancesStatus = "";
    },
    getCalendarMaintenancesFail(state, action: PayloadAction<string>) {
      state.getCalendarMaintenancesStatus = action.payload;
    },

    // -----

    getDroppableCalendarMaintenances(state) {
      state.getDroppableCalendarMaintenancesStatus = "loading";
    },
    getDroppableCalendarMaintenancesSuccess(
      state,
      action: PayloadAction<IApiResponse<IMaintenance[]>>,
    ) {
      if (action.payload.results) {
        state.droppableCalendarMaintenances = action.payload.results;
      }
      state.getDroppableCalendarMaintenancesStatus = "";
    },
    getDroppableCalendarMaintenancesFail(state, action: PayloadAction<string>) {
      state.getDroppableCalendarMaintenancesStatus = action.payload;
    },

    cancelMaintenance(state) {
      state.cancelMaintenanceStatus = "loading";
    },
    cancelMaintenanceSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        let tmp = [...state.liveMaintenances];
        let index = tmp.findIndex((x) => x.id === action.payload.results?.id);
        if (index > -1) {
          tmp.splice(index, 1);
        }
        state.maintenanceDetails = action.payload.results;
        state.liveMaintenances = tmp;
      }
      state.cancelMaintenanceStatus = "";
    },
    cancelMaintenanceFail(state, action: PayloadAction<string>) {
      state.cancelMaintenanceStatus = action.payload;
    },

    // -----

    getLiveMaintenances(state, action: PayloadAction<string>) {
      state.getLiveMaintenancesStatus = action.payload;
    },
    getLiveMaintenancesSuccess(state, action: PayloadAction<IApiResponse<IMaintenance[]>>) {
      if (action.payload.results) {
        state.liveMaintenances = action.payload.results;
      }
      state.getLiveMaintenancesStatus = "";
    },
    getLiveMaintenancesFail(state, action: PayloadAction<string>) {
      state.getLiveMaintenancesStatus = action.payload;
    },

    // -----

    writeReport(state) {
      state.writeReportStatus = "loading";
    },
    writeReportSuccess(state, action: PayloadAction<IApiResponse<IUserReport>>) {
      state.writeReportStatus = "";
    },
    writeReportFail(state, action: PayloadAction<string>) {
      state.writeReportStatus = action.payload;
    },

    // -----

    updateUserReport(state) {
      state.updateUserReportStatus = "loading";
    },
    updateUserReportSuccess(
      state,
      action: PayloadAction<IApiResponse<{ id: number; text: string }>>,
    ) {
      state.updateUserReportStatus = "";
    },
    updateUserReportFail(state, action: PayloadAction<string>) {
      state.updateUserReportStatus = action.payload;
    },

    // -----

    addSingleTask(state) {
      state.addSingleTaskStatus = "loading";
    },
    addSingleTaskSuccess(state, action: PayloadAction<IApiResponse<ITask[]>>) {
      state.addSingleTaskStatus = "";
    },
    addSingleTaskFail(state, action: PayloadAction<string>) {
      state.addSingleTaskStatus = action.payload;
    },

    // -----

    addTaskResul(state, action: PayloadAction<number>) {
      state.addTaskResulStatus = "loading" + action.payload;
    },
    addTaskResulSuccess(state, action: PayloadAction<IApiResponse<ITask[]>>) {
      if (action.payload.results) {
        state.maintenanceDetails = {
          ...state.maintenanceDetails,
          tasks: action.payload.results,
        };
      }
      state.addTaskResulStatus = "";
    },
    addTaskResulFail(state, action: PayloadAction<string>) {
      state.addTaskResulStatus = action.payload;
    },

    // -----

    addCheckListToOrder(state) {
      state.addCheckListToOrderStatus = "loading";
    },
    addCheckListToOrderSuccess(state, action: PayloadAction<IApiResponse<ITask[]>>) {
      state.addCheckListToOrderStatus = "";
    },
    addCheckListToOrderFail(state, action: PayloadAction<string>) {
      state.addCheckListToOrderStatus = action.payload;
    },

    // -----

    addExpense(state) {
      state.addExpenseStatus = "loading";
    },
    addExpenseSuccess(state, action: PayloadAction<IApiResponse<IExpense>>) {
      if (action.payload.results) {
        const tmp = [...state.expenses];
        tmp.unshift(action.payload.results);
        state.expenses = tmp;
      }
      state.addExpenseStatus = "";
    },
    addExpenseFail(state, action: PayloadAction<string>) {
      state.addExpenseStatus = action.payload;
    },

    // -----

    getExpenses(state) {
      state.getExpensesStatus = "loading";
    },
    getExpensesSuccess(state, action: PayloadAction<IApiResponse<IExpense[]>>) {
      if (action.payload.results) {
        state.expenses = action.payload.results;
      }
      state.getExpensesStatus = "";
    },
    getExpensesFail(state, action: PayloadAction<string>) {
      state.getExpensesStatus = action.payload;
    },

    // -----

    editExpense(state) {
      state.editExpenseStatus = "loading";
    },
    editExpenseSuccess(state, action: PayloadAction<IApiResponse<IExpense>>) {
      if (action.payload.results) {
        const arr = [...state.expenses];
        let index = arr.findIndex((item) => item.id === action.payload.results?.id);
        arr.splice(index, 1, action.payload.results);
        state.expenses = arr;
      }
      state.editExpenseStatus = "";
    },
    editExpenseFail(state, action: PayloadAction<string>) {
      state.editExpenseStatus = action.payload;
    },
    // -----

    setTaskRank(state) {
      state.setTaskRankStatus = "loading";
    },
    setTaskRankSuccess(state, action: PayloadAction<IApiResponse<ITask[]>>) {
      state.setTaskRankStatus = "";
    },
    setTaskRankFail(state, action: PayloadAction<string>) {
      state.setTaskRankStatus = action.payload;
    },

    // -----

    confirmOrder(state) {
      state.confirmOrderStatus = "loading";
    },
    confirmOrderSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      state.confirmOrderStatus = "";
    },
    confirmOrderFail(state, action: PayloadAction<string>) {
      state.confirmOrderStatus = action.payload;
    },

    // -----

    updateMaintenance(state) {
      state.updateMaintenanceStatus = "loading";
    },
    updateMaintenanceSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        state.maintenanceDetails = action.payload.results;
      }
      state.updateMaintenanceStatus = "";
    },
    updateMaintenanceFail(state, action: PayloadAction<string>) {
      state.updateMaintenanceStatus = action.payload;
    },
    // -----

    addWorkingTime(state) {
      state.addWorkingTimeStatus = "loading";
    },
    addWorkingTimeSuccess(state, action: PayloadAction<IApiResponse<IFlow[]>>) {
      if (action.payload.results) {
        state.maintenanceDetails = {
          ...state.maintenanceDetails,
          flows: action.payload.results,
        };
      }
      state.addWorkingTimeStatus = "";
    },
    addWorkingTimeFail(state, action: PayloadAction<string>) {
      state.addWorkingTimeStatus = action.payload;
    },

    // -----

    getWorkCosts(state) {
      state.getWorkCostsStatus = "loading";
    },
    getWorkCostsSuccess(state, action: PayloadAction<IApiResponse<IWorkCost[]>>) {
      if (action.payload.results) {
        state.workCosts = action.payload.results;
      }
      state.getWorkCostsStatus = "";
    },
    getWorkCostsFail(state, action: PayloadAction<string>) {
      state.getWorkCostsStatus = action.payload;
    },

    // -----

    getTemplates(state) {
      state.getTemplatesStatus = "loading";
    },
    getTemplatesSuccess(
      state,
      action: PayloadAction<IApiResponse<ITemplate[]> & { mergeData: boolean }>,
    ) {
      if (!action.payload.results) {
        return;
      }
      if (action.payload.mergeData) {
        state.templates = [...state.templates, ...action.payload.results];
      } else {
        state.templates = action.payload.results;
      }
      state.getTemplatesStatus = "";
    },
    getTemplatesFail(state, action: PayloadAction<string>) {
      state.getTemplatesStatus = action.payload;
    },

    // -----

    createTemplate(state) {
      state.createTemplateStatus = "loading";
    },
    createTemplateSuccess(state, action: PayloadAction<IApiResponse<ITemplate>>) {
      if (action.payload.results) {
        let tmp = [...state.templates];
        tmp.push(action.payload.results);
        state.templates = tmp;
      }
      state.createTemplateStatus = "";
    },
    createTemplateFail(state, action: PayloadAction<string>) {
      state.createTemplateStatus = action.payload;
    },

    // -----

    getTemplateDetails(state) {
      state.getTemplateDetailsStatus = "loading";
    },
    getTemplateDetailsSuccess(state, action: PayloadAction<IApiResponse<ITemplateDetails>>) {
      if (action.payload.results) {
        state.templateDetails = action.payload.results;
      }
      state.getTemplateDetailsStatus = "";
    },
    getTemplateDetailsFail(state, action: PayloadAction<string>) {
      state.getTemplateDetailsStatus = action.payload;
    },

    // -----

    updateTemplateDetails(state) {
      state.updateTemplateDetailsStatus = "loading";
    },
    updateTemplateDetailsSuccess(state, action: PayloadAction<IApiResponse<ITemplateDetails>>) {
      if (action.payload.results) {
        state.templateDetails = action.payload.results;
      }
      state.updateTemplateDetailsStatus = "";
    },
    updateTemplateDetailsFail(state, action: PayloadAction<string>) {
      state.updateTemplateDetailsStatus = action.payload;
    },

    // -----

    addExecutorsToTemplate(state) {
      state.addExecutorsToTemplateStatus = "loading";
    },
    addExecutorsToTemplateSuccess(state, action: PayloadAction<IApiResponse<ITemplateDetails>>) {
      if (action.payload.results) {
        state.templateDetails = action.payload.results;
      }
      state.addExecutorsToTemplateStatus = "";
    },
    addExecutorsToTemplateFail(state, action: PayloadAction<string>) {
      state.addExecutorsToTemplateStatus = action.payload;
    },

    // -----

    addTemplateMaterials(state) {
      state.addTemplateMaterialsStatus = "loading";
    },
    addTemplateMaterialsSuccess(state, action: PayloadAction<IApiResponse<IFile[]>>) {
      state.addTemplateMaterialsStatus = "";
    },
    addTemplateMaterialsFail(state, action: PayloadAction<string>) {
      state.addTemplateMaterialsStatus = action.payload;
    },

    // -----

    addTaskToTemplate(state) {
      state.addTaskToTemplateStatus = "loading";
    },
    addTaskToTemplateSuccess(state, action: PayloadAction<IApiResponse<ITask>>) {
      if (action.payload.results) {
        if (state.templateDetails) {
          let tmp = [...state.templateDetails?.tasks];
          tmp.push(action.payload.results);
          state.templateDetails = {
            ...state.templateDetails,
            tasks: tmp,
          };
        }
      }

      state.addTaskToTemplateStatus = "";
    },
    addTaskToTemplateFail(state, action: PayloadAction<string>) {
      state.addTaskToTemplateStatus = action.payload;
    },

    // -----

    getWorkingCosts(state) {
      state.getWorkingCostsStatus = "loading";
    },
    getWorkingCostsSuccess(state, action: PayloadAction<IApiResponse<IWorkCostDetail[]>>) {
      if (action.payload.results) {
        state.workingCostsDetail = action.payload.results;
      }
      state.getWorkingCostsStatus = "";
    },
    getWorkingCostsFail(state, action: PayloadAction<string>) {
      state.getWorkingCostsStatus = action.payload;
    },

    // -----
    createOrderFromTemplate(state) {
      state.createOrderFromTepmlateStatus = "loading";
    },
    createOrderFromTemplateSuccess(
      state,
      action: PayloadAction<IApiResponse<IMaintenanceDetails>>,
    ) {
      state.createOrderFromTepmlateStatus = "";
    },
    createOrderFromTemplateFail(state, action: PayloadAction<string>) {
      state.createOrderFromTepmlateStatus = action.payload;
    },

    // -----

    getWorkRequests(state) {
      state.getWorkRequestsStatus = "loading";
    },
    getWorkRequestsSuccess(state, action: PayloadAction<IApiResponse<IWorkRequest[]>>) {
      state.workRequests = action.payload.results || [];
      state.getWorkRequestsStatus = "";
    },
    getWorkRequestsFail(state, action: PayloadAction<string>) {
      state.getWorkRequestsStatus = action.payload;
    },

    // -----

    createWorkRequest(state) {
      state.createWorkRequestStatus = "loading";
    },
    createWorkRequestSuccess(state, action: PayloadAction<IApiResponse<IWorkRequest>>) {
      state.createWorkRequestStatus = "";
    },
    createWorkRequestFail(state, action: PayloadAction<string>) {
      state.createWorkRequestStatus = action.payload;
    },
  },
});

export default maintenanceSlice.reducer;
